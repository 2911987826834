import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import "./EventsSection.css";
import {Typography} from "@mui/material";
import Globals from "../../globals/Globals";
import axios from "axios";
import List from "@mui/material/List";
import "../../mobile/calendar/EventCalendarPage.css";
import OnepageEvent from "./OnepageEvent";
import OnePageEmptyEvent from "./OnePageEmptyEvent";


function EventsSection () {

    const apiUrl = Globals.apiUrl + "images/events";
    const eventsUrl = Globals.apiUrl + "events/all";
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        axios.get(eventsUrl)
            .then(response => {
                console.log(response.data);
                setEvents(response.data.reverse());
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return(
        <Box className={"eventsSection"}>
            <Typography variant={"h3"} className={"eventsSectionTitle"}>Hier findest du mich:</Typography>
            <List className={"eventsSectionList"}>
                {events.length > 0 ? (
                    events.map(event => (
                        <OnepageEvent key={event.name + "@" + event.date} name={event.name} date={event.date} lat={event.lat} lng={event.lng}/>
                    ))) : (
                    <OnePageEmptyEvent />
                )}
            </List>
        </Box>
    );
}

export default EventsSection;