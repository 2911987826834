import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React from "react";


function OnePageEmptyEvent() {

    return (
        <ListItem className={"eventsSectionListItem"}>
            <ListItemButton className={"eventSectionListItemButton"}>
                <ListItemText primary={"Aktuell sind keine Events geplant 😢"} classes={{ primary: "eventsSectionListItemTextPrimaryEmpty"}}/>
            </ListItemButton>
        </ListItem>
    );
}

export default OnePageEmptyEvent;